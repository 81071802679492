import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import cnLogo from "../../data/images/CNlogo_padded.jpg";
import bnsfLogo from "../../data/images/BNSFlogo_padded.jpg";
import uprrLogo from "../../data/images/UPlogo_padded.jpg";
import nsLogo from "../../data/images/NSlogo_Padded.jpg";
import kcsLogo from "../../data/images/KCSlogo_Padded.jpg";
import csxLogo from "../../data/images/CSXlogo_Padded.jpg";
import cpLogo from "../../data/images/CPLogo_Padded.jpg"
import ptc220LogoPadded from "../../data/images/ptc220Logo_Padded.jpg";
import ptc220Logo from "../../data/images/PTC220Logo.png";
import concentric from "../../data/images/Concentric.png";
import {
  Card,
  Segment,
  Container,
  Grid,
  Header,
  Icon, 
  Image,
  Table,
  TableBody,
} from "semantic-ui-react";
import { groupEnd } from "console";

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
  return array;
}
//var logos = shuffleArray(
var logos = ([
  {
    image: ptc220LogoPadded,
    description: 'PTC-220 LLC',
    href: 'https://ptc220.org',
  },
  {
    image: cnLogo,
    description: 'Canadian National Railway',
    href: 'https://www.cn.ca',
  },
  {
    image: bnsfLogo,
    description: 'BNSF Railway',
    href: 'https://www.bnsf.com',
  },
  {
    image: uprrLogo,
    description:  'Union Pacific Railway',
    href: 'https://www.up.com',
  },  
  {
    image: nsLogo,
    description:  'Norfolk Southern Railway',
    href: 'https://www.nscorp.com',
  },
  {
    image: kcsLogo,
    description:  'Kansas City Southern Railway',
    href: 'https://www.kcsouthern.com',
  },
  {
    image: csxLogo,
    description:  'CSX Railway',
    href: 'https://www.csx.com',
  },
  {
    image: cpLogo,
    description:  'CP Railway',
    href: 'https://www.cpr.ca',
  },
])

const CurrentOfficers = () => (
      <Grid columns="4" textAlign="center" divided relaxed stackable className="container">
        <Grid.Row>
          <Grid.Column>
            <Header icon>
              <Icon name="circle" size="large"></Icon>
              Kelechi Nwogu (UP)
            </Header>
            <p>
            President, PTC-220
            </p>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
              <Icon name="circle" size="large" ></Icon>
              Pierre Leclerc
            </Header>
            <p>
              Corporate Secretary &amp; Spectrum Coordinator
            </p>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
              <Icon name="circle" size="large"></Icon>
              Andrew Sutherland (NS)
            </Header>
            <p>
              Chairman, Spectrum Management Committee
            </p>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
              <Icon name="circle" size="large"></Icon>
              Joshua Greer (KCS)
            </Header>
            <p>
            Chairman, Technical Advisory Committee
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>

)

const PastOfficerTable = () => (
  <Grid stackable verticalAlign="top" className="container"  textAlign="center">
  <Grid.Row>
    <Grid.Column width="16">
  <Table definition >
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Term </Table.HeaderCell>
      <Table.HeaderCell>PTC-220 President </Table.HeaderCell>
      <Table.HeaderCell>SMC Chairman </Table.HeaderCell>
      <Table.HeaderCell>PAC Chairman </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
  <Table.Body>
    <Table.Row>
      <Table.Cell>July 2021 - July 2023 </Table.Cell>
      <Table.Cell>Tom Burns (KCS) </Table.Cell>
      <Table.Cell>Simon Beliveau (CN)  </Table.Cell>
      <Table.Cell>Ryan Hartley/John Jimison (CSX) </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>July 2019 - July 2021 </Table.Cell>
      <Table.Cell>Kevin Waldern (CP) </Table.Cell>
      <Table.Cell>Mike Dizor (CSX) </Table.Cell>
      <Table.Cell>Praveen Krishnan (BNSF) </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>July 2017 - July 2019</Table.Cell>
      <Table.Cell>Kim Simmonds (CN) </Table.Cell>
      <Table.Cell>Tom Burns (KCS) </Table.Cell>
      <Table.Cell>Kelechi Nwogu (KC) (UP) </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>July 2015 - July 2017</Table.Cell>
      <Table.Cell>Mike Lannan (BNSF) </Table.Cell>
      <Table.Cell>Kevin Waldern (CP) </Table.Cell>
      <Table.Cell>Enzo DeBenetti (CN) </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>July 2013 - July 2015</Table.Cell>
      <Table.Cell>Henry McCreary (CSX) </Table.Cell>
      <Table.Cell>Mike Lannan (BNSF) </Table.Cell>
      <Table.Cell>Joshua Allison (NS) </Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>July 2011 - July 2013</Table.Cell>
      <Table.Cell>Tom Schnautz (NS) </Table.Cell>
      <Table.Cell>Ed Hollingsworth (UP) </Table.Cell>
      <Table.Cell>Joshua Allison (NS) </Table.Cell>
    </Table.Row>

  </Table.Body>
</Table>
        </Grid.Column>
          </Grid.Row>
          </Grid>
)

const IndexPage = (props: LayoutProps) =>

  <div>
    {/* Master head */}
      <Segment vertical textAlign="center" className="masthead" 
            style={{
              backgroundImage: `url(${concentric})`,
              backgroundSize: 'cover',
              fontWeight: 'normal',
              marginBottom: 0,
            }}>
      <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
      />  
      <Container >
        <Image src={ptc220Logo} centered style={{ marginTop: '4em' }}/>
      </Container> 
    </Segment>

    { <Segment vertical className="stripe">
      <Grid stackable verticalAlign="top" className="container">
        <Grid.Row>
          <Grid.Column width="8">
            {/* <Header>What is PTC-220</Header>
            <p>
              The 220 MHz band is the main one selected for PTC use (although some commuter
              and passenger railroads, mostly on the East Coast, are using 217-218 MHz for
              PTC using a different protocol).  The Federal Communications Commission (FCC)
              has granted numerous licenses to PTC-220, each using spectrum between 219.5-222
              MHz, for a nationwide, interoperable PTC network, and has granted special
              waivers to accommodate PTC on behalf of all US railways. 
            </p> */}
            <Header>What is PTC-220?</Header>
             <Container textAlign='justified' ><p>
               PTC-220 is a consortium held by the seven Class I railroads. It is a spectrum holding company and licensee for various FCC call signs in the 219 and 220/221 MHz bands. Its purpose is to provide and manage spectrum needed to operate specific safety systems such as Positive Train Control, Traffic Control, and other Train Control related systems.  
               </p></Container>
             <Header>Who owns PTC-220?</Header>
            <Container textAlign="justified"><p>
              PTC-220 is an LLC owned by its seven members:  BNSF, Canadian National, Canadian Pacific, CSX, Kansas City Southern, Norfolk Southern, and Union Pacific. These railroads have contributed the funds needed to acquire the necessary auctioned spectrum for statutorily-mandated PTC networks, and have worked with the FCC to obtain the needed operational authorities.  PTC-220 also has “non-member” spectrum users, who typically lease spectrum from PTC-220 or obtain rights through a member.
              </p></Container>
        </Grid.Column>
          <Grid.Column width="8" floated="right" > 
            <Header>How is PTC-220 Managed and Governed?</Header>
            <Container textAlign="justified">
              <p>
                  Most of PTC-220’s operations are overseen by three bodies.  General governance is overseen by a Management Committee (MC), and technical matters related to the spectrum are overseen by a Spectrum Management Committee (SMC) and Technical Advisory Committee (PAC).  MC, SMC and PAC are composed of representatives of the members of PTC-220.  The MC has overseen and approved the various spectrum acquisitions by PTC-220.  The SMC and PAC has adopted technologies and protocols for use with PTC so that the networks of the various railroads will be interoperable.
              </p>
            </Container>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
          <Container>
            <Header>What are PTC-220's Functions?</Header>
              <Container  textAlign="justified">
              <p>
                <ul>
                  <li>Holds spectrum licenses used by railroads in the U.S. to operate Positive Train Control (PTC) networks mandated by statute, and is engaged in acquiring additional spectrum to support related train safety applications;</li>
                  <li>Supports spectrum needs for railroads through lease arrangements (members & non-members), including passenger and commuter railroads that need I-ETMS® protocol capability;</li>
                  <li>Coordinates RF design, interference mitigation, leveraging expertise across the industry;</li>
                  <li>Ability to accommodate/manage non-member owned spectrum;</li>
                  <li>Coordinates with commuter and passenger railroads that hold various licenses in the 217 and 218 MHz bands for PTC using a different protocol to avoid interference between networks (i.e., ACSES). </li>
                </ul>
              </p>
              </Container>
              </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment> 
    }

    { 
    <Segment vertical className="stripe alternate feature" textAlign="center">
        <Header as="h2">Who currently holds the key positions in PTC-220?</Header><br/>
          <CurrentOfficers /><br/>
        <Header as="h2">Previous Officers</Header><br/>
        <PastOfficerTable />

    </Segment> }
    { <Segment inverted vertical textAlign="center" >
      <Container>
        <Card.Group items={logos} stackable itemsPerRow='4' />
      </Container>
    </Segment>
    }
  </div>;

export default withLayout(IndexPage);
